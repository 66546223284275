import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { setTheme } from '../../../store/user/userSlice';

const ThemeSwitchButton = () => {
  const themeFromRedux = useAppSelector(state => state.user.theme);
  const dispatch = useAppDispatch();

  return (
    <button
      onClick={() => {
        dispatch(setTheme(themeFromRedux === 'dark' ? 'light' : 'dark'));
      }}
    >
      Theme: {themeFromRedux}
    </button>
  );
};

export default ThemeSwitchButton;
